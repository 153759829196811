import LogRocket from "logrocket";
import React, { useEffect } from "react";
import { useStore } from "~/state";

interface SessionContextProps {
	user?: any;
	profile?: any;
	organisations: any[];
	isAuthenticated: boolean;
	canRefresh: boolean;
	env?: any;
}

const SessionContext = React.createContext<SessionContextProps>({
	organisations: [],
	isAuthenticated: false,
	canRefresh: false,
});

export const SessionProvider: React.FC<{ children: any; session: any }> = ({
	children,
	session,
}) => {
	const { setProfile, setUser } = useStore();

	let { user, profile, organisations } = session || {};
	const access = user?.access;
	const refresh = user?.refresh;

	// Save in client store
	useEffect(() => {
		setUser({ ...user, profile });
		setProfile(profile);
	}, [user, profile, setUser, setProfile]);

	useEffect(() => {
		LogRocket.identify(user?.uuid, {
			name: user?.name,
			email: user?.email,

			// Add your own custom user variables here, ie:
			env: ENV.MODE,
		});
	}, []);

	const hasRefreshToken = !access && !!refresh;
	const isAuthenticated = !!profile && !!access;

	const contextValue: SessionContextProps = {
		user: { ...user, profile },
		profile,
		organisations,
		isAuthenticated,
		canRefresh: !access && !profile && hasRefreshToken,
	};

	return (
		<SessionContext.Provider value={contextValue}>
			{children}
		</SessionContext.Provider>
	);
};

export const useSession = (): SessionContextProps => {
	return React.useContext(SessionContext);
};
